import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import SmartText from "../../utils/TextHandler";
import Background from "../Global/Background";
import Image from "../Global/Image";
import Link from "../Global/Link";
import Ratings from "../Global/Ratings";

const Hero = (props) => {
  const { data } = props;
  const delay = 5000;

  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  function startTimer() {
    resetTimeout();
    timeoutRef.current = setTimeout(() => nextSlide(), delay);
    return () => {
      resetTimeout();
    };
  }
  function nextSlide(slide) {
    const next = index + 1;
    next === data.length ? setIndex(0) : setIndex(next);
  }
  function startVideo() {
    setPlaying(true);
  }
  function endVideo() {
    setPlaying(false);
    nextSlide();
  }
  useEffect(() => {
    /*

    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };

    */
  }, [index]);

  return (
    <div className="position-relative">
      <div className="ratio ratio-16x9 brand-radius brand-background-black brand-text-white">
        {data.map((node, idx) => {
          if (idx === index) {
            if (node.component === "link_game") {
              return (
                <Background
                  className={`d-flex flex-column justify-content-center align-items-center brand-video-loading brand-game-link ${
                    playing === true ? "brand-video-playing" : ""
                  }`}
                  src={node.game.content.background_letterbox?.filename}
                  key={idx}
                >
                  {node.game.content.sting?.vimeo_raw ? (
                    <ReactPlayer
                      url={node.game.content.sting?.vimeo_raw}
                      width="100%"
                      height="100%"
                      className="brand-video-dynamic"
                      onStart={() => startVideo()}
                      onEnded={() => endVideo()}
                      config={{
                        vimeo: {
                          playerOptions: {
                            background: true,
                            playsinline: true,
                            loop: false,
                            dnt: true,
                            responsive: true,
                          },
                        },
                      }}
                    />
                  ) : (
                    <>No video for this game {startTimer()}</>
                  )}
                  <div className="text-center position-absolute w-100 h-100 top-0 end-0 bottom-0 start-0 d-flex flex-column justify-content-center align-items-center brand-game-logo">
                    <Image
                      src={node.game.content.logo?.filename}
                      sm="200x200"
                      md="500x500"
                      lg="600x600"
                      alt=""
                      className="brand-game-logo-image"
                    />
                    <div className="text-center position-absolute end-0 bottom-0 start-0 d-flex flex-row justify-content-center align-items-center">
                      <Link
                        button
                        icon
                        colour="white"
                        className="brand-game-button"
                      >
                        More
                      </Link>
                    </div>
                  </div>
                  <Link to={node.game.full_slug} stretched />
                </Background>
              );
            } else if ((node.component = "item_hero")) {
              return (
                <Background
                  className={`d-flex justify-content-center align-items-center`}
                  src={node.image?.filename}
                  key={idx}
                >
                  <div className="text-center">
                    {startTimer()}
                    {node.title && <h2 className="brand-font">{node.title}</h2>}
                    {node.body && <SmartText>{node.body}</SmartText>}
                    {node.button && (
                      <Link
                        to={node.link.story.full_slug}
                        button
                        icon
                        colour="white"
                        stretched
                      >
                        {node.button}
                      </Link>
                    )}
                  </div>
                </Background>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default Hero;

Hero.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Hero.defaultProps = {
  settings: null,
};

/*
            <div className="position-relative">
              <ReactPlayer
                url={item.video.vimeo_raw}
                width="100%"
                height="100%"
                className="ratio ratio-16x9 brand-radius"
                config={{
                  vimeo: {
                    playerOptions: {
                      background: true,
                      loop: true,
                      dnt: true,
                      responsive: true,
                    },
                  },
                }}
              />
              <div className="position-absolute bottom-0 start-0 brand-text-white p-3 w-auto h-auto">
                &lt; {JSON.stringify(item.link.story.content.ratings)} &gt;
              </div>
            </div>
*/
