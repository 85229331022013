import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Banner from "../components/Hero/Banner";
import Title from "../components/Title/Title";
import SmartText from "../utils/TextHandler";
import Sections from "../components/Sections/Sections";

// ====

const Page = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      {(doc.hero_title || doc.hero_body || doc.hero_image) && (
        <Banner
          title={doc.hero_title && doc.hero_title}
          description={doc.hero_body && doc.hero_body}
          image={doc.hero_image && doc.hero_image}
        />
      )}
      <Sections items={doc.sections} />
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Page.defaultProps = {
  pageContext: null,
  blok: null,
};
