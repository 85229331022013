import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Banner from "../components/Hero/Banner";
import Title from "../components/Title/Title";
import { Reveal, Tween } from "react-gsap";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";
import CardVacancy from "../components/Cards/CardVacancy";

// ====

const Careers = (props) => {
  const { pageContext, blok } = props;
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  const jobPosts = useStaticQuery(graphql`
    {
      jobs: allStoryblokEntry(
        filter: { field_component: { in: ["career"] } }
        sort: { fields: field_date_string, order: ASC }
      ) {
        nodes {
          field_component
          field_date_string
          full_slug
          lang
          content
        }
      }
    }
  `);
  let jobs;
  if (jobPosts) {
    jobs = jobPosts.jobs;
  }

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <Banner
        title={doc.hero_title}
        description={doc.hero_body}
        image={doc.hero_image}
      />
      <section className="py-5">
        <div className="container">
          <Title
            title={doc.title}
            description={doc.body}
            supporting={Object.keys(jobs.nodes).length}
          />
          {jobs && (
            <div className="row gy-4 justify-content-start">
              <>
                {jobs.nodes.map((node, index) => {
                  const delay = (1 * index) / 10;

                  return (
                    <Tween
                      from={{ y: "100px", opacity: 0 }}
                      duration={0.75}
                      delay={delay}
                      ease="back.out(1.5)"
                    >
                      <div className="col-12 col-md-6">
                        <CardVacancy item={node} />
                      </div>
                    </Tween>
                  );
                })}
              </>
            </div>
          )}
        </div>
      </section>
      <Sections items={doc.sections} />
    </Layout>
  );
};

export default Careers;

Careers.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Careers.defaultProps = {
  pageContext: null,
  blok: null,
};
