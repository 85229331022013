import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Image from "../components/Global/Image";
import SmartText from "../utils/TextHandler";
import Title from "../components/Title/Title";
import CardGame from "../components/Cards/CardGame";

// ====

const News_Post = ({ blok, data, pageContext }) => {
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }

  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section className="py-5">
        <div className="container">
          <Title
            title={doc.title}
            date={doc.date}
            share={[{ meta: doc.meta, openGraph: openGraph }]}
          />
          <div className="row justify-content-between">
            <div className="col-12 col-md-8">
              {doc.image && doc.image.filename && (
                <div className="mb-5">
                  <Image
                    src={doc.image.filename}
                    sm="1920x1080"
                    md="1920x1080"
                    lg="1920x1080"
                    alt={doc.title && doc.title}
                    className="brand-radius w-100"
                  />
                </div>
              )}

              <SmartText>{doc.body}</SmartText>
            </div>
            <div className="col-12 col-md-3">
              {doc.game && (
                <CardGame
                  item={doc.game}
                  sm="200x200"
                  md="260x260"
                  lg="470x470"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default News_Post;

News_Post.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

News_Post.defaultProps = {
  pageContext: null,
  blok: null,
};
