import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import Image from "../Global/Image";
import Moment from "react-moment";
import SmartTag from "../../utils/TagHandler";
import Ratings from "../Global/Ratings";
import ShareLinks from "../Global/ShareLinks";
import Link from "../Global/Link";

const CardCareerDetails = (props) => {
  const { item, openGraph } = props;

  return (
    <div className={`d-flex flex-column brand-shadow brand-radius p-4 p-md-5`}>
      {item.department && (
        <div className="row border-bottom pb-4 mb-4">
          <div className="col-4 brand-text-black-50">Department:</div>
          <div className="col-8">{item.department}</div>
        </div>
      )}
      {item.location && (
        <div className="row border-bottom pb-4 mb-4">
          <div className="col-4 brand-text-black-50">Location:</div>
          <div className="col-8">{item.location}</div>
        </div>
      )}
      {item.type && (
        <div className="row border-bottom pb-4 mb-4">
          <div className="col-4 brand-text-black-50">Job type:</div>
          <div className="col-8">{SmartTag(item.type)}</div>
        </div>
      )}
      {item.date && (
        <div className="row border-bottom pb-4 mb-4">
          <div className="col-4 brand-text-black-50">Deadline:</div>
          <div className="col-8">
            <Moment format="Do MMM, YYYY" date={item.date} />
          </div>
        </div>
      )}
      {item.apply && (
        <div className="row border-bottom pb-4 mb-4 text-center ">
          <Link
            button
            to={item.apply}
            icon
            subject={`Job Application - ${item.title}`}
          >
            Apply
          </Link>
        </div>
      )}
      <div className="row text-center ">
        <ShareLinks
          meta={item.meta}
          openGraph={openGraph}
          facebook
          twitter
          linkedin
          email
        />
      </div>{" "}
    </div>
  );
};

export default CardCareerDetails;

CardCareerDetails.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardCareerDetails.defaultProps = {
  item: null,
};
