import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Moment from "react-moment";
import SmartImage from "../utils/ImageHandler";
import SmartText from "../utils/TextHandler";
import Title from "../components/Title/Title";
import CardCareerDetails from "../components/Cards/CardCareerDetails";

// ====

const Career = ({ blok, data, pageContext }) => {
  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }

  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <section className="py-5">
        <div className="container">
          <Title title={doc.title} />
          <div className="row justify-content-between">
            <div className="col-12 col-lg-7 mb-5 mb-lg-0">
              <SmartText>{doc.body}</SmartText>
            </div>
            <div className="col-12 col-lg-4">
              <CardCareerDetails item={doc} openGraph={openGraph} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Career;

Career.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blok: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Career.defaultProps = {
  pageContext: null,
  blok: null,
};
