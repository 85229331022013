import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import SmartTag from "../../utils/TagHandler";
import Link from "../Global/Link";

const CardVacancy = (props) => {
  const { item, size } = props;

  const doc = JSON.parse(item.content);

  return (
    <div
      className={`d-flex flex-column brand-shadow brand-shadow-hover brand-radius p-4 p-md-5 h-100 position-relative`}
    >
      {doc.title && <h3 className="fw-bold mb-3">{doc.title}</h3>}
      <div className="row h-100 justify-content-between">
        <div className="col-12 col-sm col-md-12 col-lg mb-3 mb-lg-0">
          {doc.department && (
            <div className="row">
              <div className="col-6 col-xl-4 brand-text-black-50">
                Department:
              </div>
              <div className="col">{doc.department}</div>
            </div>
          )}
          {doc.location && (
            <div className="row">
              <div className="col-6 col-xl-4 brand-text-black-50">
                Location:
              </div>
              <div className="col">{doc.location}</div>
            </div>
          )}
          {doc.type && (
            <div className="row">
              <div className="col-6 col-xl-4 brand-text-black-50">
                Job type:
              </div>
              <div className="col">{SmartTag(doc.type)}</div>
            </div>
          )}
        </div>
        <div className="col-12 col-sm-auto col-md-12 col-lg-auto d-flex justify-content-end align-items-end">
          <Link to={item.full_slug} button icon>
            More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardVacancy;

CardVacancy.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardVacancy.defaultProps = {
  settings: null,
};
