import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Hero/Hero";
import SmartText from "../utils/TextHandler";
import Link from "../components/Global/Link";
import Sections from "../components/Sections/Sections";

import Modal, { ModalBody, ModalFooter } from "../components/Templates/Modal";

// Markup
const IndexPage = (props) => {
  const { data, blok } = props;

  let doc;
  let news;
  if (data) {
    doc = JSON.parse(data.index.content);
    news = data.newsPosts;
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
  };

  const modalRefA = useRef();

  return (
    <Layout>
      <Meta meta={doc.meta} />
      <section className="position-relative">
        <div className="h-50 brand-background-black position-absolute w-100" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Hero data={doc.heros} />
            </div>
          </div>
        </div>
      </section>

      <Sections items={doc.sections} />

      <Modal ref={modalRefA} title="Hello">
        <ModalBody>
          <h3>Modal window A</h3>
          <p>Modal windows can come in like this</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => modalRefA.current.closeModal()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default IndexPage;

export const QUERY = graphql`
  query IndexPageQuery {
    index: storyblokEntry(slug: { eq: "home" }) {
      content
    }
  }
`;
